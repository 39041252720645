import { createStore } from 'vuex'

export default createStore({
  state: {
    isAsideShow: true,
    isLoginBoxShow: false,
    token: localStorage.token || ''
  },
  mutations: {
    setIsAsideShow (state, v) {
      state.isAsideShow = v
    },
    setIsLoginBoxShow (state, v) {
      state.isLoginBoxShow = v
    },
    setToken (state, v) {
      state.token = v
    },
    clearToken (state) {
      state.token = ''
      localStorage.removeItem('token');
    }
  },
  actions: {
  },
  modules: {
  }
})
