export const optionsData = [
  {
    id: 1,
    label: '矿业权申请书',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '申请人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: 'mineLocation',
            placeholder: '矿区位置'
          },
          {
            id: 'mineArea',
            placeholder: '矿区面积'
          },
          {
            id: 'mineType',
            placeholder: '矿种'
          },
          {
            id: 'miningMethod',
            placeholder: '开采方式'
          },
          {
            id: 'miningDeadline',
            placeholder: '开采期限'
          },
          {
            id: 'miningScale',
            placeholder: '开采规模'
          },
          {
            id: 'miningPlan',
            placeholder: '开采方案'
          },
          {
            id: 'envProtect',
            placeholder: '环境保护措施'
          },
        ]
      }
    ]
  },
  {
    id: 2,
    label: '矿业权评估报告',
    inputs: [
      {
        label: '其他信息',
        value: [
          {
            id: 'mineGeology',
            placeholder: '矿区地质情况'
          },
          {
            id: 'mineType',
            placeholder: '矿种'
          },
          {
            id: 'miningConditions',
            placeholder: '矿区开采条件'
          },
          {
            id: "marketProspect",
            placeholder: "矿区市场前景"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "矿区环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 3,
    label: '矿业权转让协议书',
    inputs: [
      {
        label: '转让方基本信息',
        value: [
          {
            id: 'transferorName',
            placeholder: '转让方姓名'
          },
          {
            id: 'transferorCompany',
            placeholder: '转让方企业名称'
          },
          {
            id: 'transferorId',
            placeholder: '转让方身份证号'
          },
          {
            id: 'transferorCompanyId',
            placeholder: '转让方企业注册号'
          },
          {
            id: 'transferorAddress',
            placeholder: '转让方联系地址'
          },
          {
            id: 'transferorPhone',
            placeholder: '转让方电话号码'
          },
          {
            id: 'transferorEmail',
            placeholder: '转让方电子邮箱'
          },
          {
            id: 'transferorLegalRepresentative',
            placeholder: '转让方法定代表人'
          },
          {
            id: 'transferorAuthorizedAgent',
            placeholder: '转让方授权代理人'
          }
        ]
      },
      {
        label: '受让方基本信息',
        value: [
          {
            id: 'TransfereeName',
            placeholder: '受让方姓名'
          },
          {
            id: 'TransfereeCompany',
            placeholder: '受让方企业名称'
          },
          {
            id: 'TransfereeId',
            placeholder: '受让方身份证号'
          },
          {
            id: 'TransfereeCompanyId',
            placeholder: '受让方企业注册号'
          },
          {
            id: 'TransfereeAddress',
            placeholder: '受让方联系地址'
          },
          {
            id: 'TransfereePhone',
            placeholder: '受让方电话号码'
          },
          {
            id: 'TransfereeEmail',
            placeholder: '受让方电子邮箱'
          },
          {
            id: 'TransfereeLegalRepresentative',
            placeholder: '受让方法定代表人'
          },
          {
            id: 'TransfereeAuthorizedAgent',
            placeholder: '受让方授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 4,
    label: '矿业权出让合同',
    inputs: [
      {
        label: '出让方基本信息',
        value: [
          {
            id: 'transferorName',
            placeholder: '出让方姓名'
          },
          {
            id: 'transferorCompany',
            placeholder: '出让方企业名称'
          },
          {
            id: 'transferorId',
            placeholder: '出让方身份证号'
          },
          {
            id: 'transferorCompanyId',
            placeholder: '出让方企业注册号'
          },
          {
            id: 'transferorAddress',
            placeholder: '出让方联系地址'
          },
          {
            id: 'transferorPhone',
            placeholder: '出让方电话号码'
          },
          {
            id: 'transferorEmail',
            placeholder: '出让方电子邮箱'
          },
          {
            id: 'transferorLegalRepresentative',
            placeholder: '出让方法定代表人'
          },
          {
            id: 'transferorAuthorizedAgent',
            placeholder: '出让方授权代理人'
          }
        ]
      },
      {
        label: '受让方基本信息',
        value: [
          {
            id: 'TransfereeName',
            placeholder: '受让方姓名'
          },
          {
            id: 'TransfereeCompany',
            placeholder: '受让方企业名称'
          },
          {
            id: 'TransfereeId',
            placeholder: '受让方身份证号'
          },
          {
            id: 'TransfereeCompanyId',
            placeholder: '受让方企业注册号'
          },
          {
            id: 'TransfereeAddress',
            placeholder: '受让方联系地址'
          },
          {
            id: 'TransfereePhone',
            placeholder: '受让方电话号码'
          },
          {
            id: 'TransfereeEmail',
            placeholder: '受让方电子邮箱'
          },
          {
            id: 'TransfereeLegalRepresentative',
            placeholder: '受让方法定代表人'
          },
          {
            id: 'TransfereeAuthorizedAgent',
            placeholder: '受让方授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 5,
    label: '矿业权许可证',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '申请人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 6,
    label: '矿业权登记证书',
    inputs: [
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 7,
    label: '矿业权注销申请书',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '申请人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 8,
    label: '矿业权纠纷仲裁申请书',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '当事人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 9,
    label: '矿业权纠纷诉讼起诉书',
    inputs: [
      {
        label: '原告基本信息',
        value: [
          {
            id: 'accuserName',
            placeholder: '出让方姓名'
          },
          {
            id: 'accuserCompany',
            placeholder: '出让方企业名称'
          },
          {
            id: 'accuserId',
            placeholder: '出让方身份证号'
          },
          {
            id: 'accuserCompanyId',
            placeholder: '出让方企业注册号'
          },
          {
            id: 'accuserAddress',
            placeholder: '出让方联系地址'
          },
          {
            id: 'accuserPhone',
            placeholder: '出让方电话号码'
          },
          {
            id: 'accuserEmail',
            placeholder: '出让方电子邮箱'
          },
          {
            id: 'accuserLegalRepresentative',
            placeholder: '出让方法定代表人'
          },
          {
            id: 'accuserAuthorizedAgent',
            placeholder: '出让方授权代理人'
          }
        ]
      },
      {
        label: '被告基本信息',
        value: [
          {
            id: 'accusedName',
            placeholder: '受让方姓名'
          },
          {
            id: 'accusedCompany',
            placeholder: '受让方企业名称'
          },
          {
            id: 'accusedId',
            placeholder: '受让方身份证号'
          },
          {
            id: 'accusedCompanyId',
            placeholder: '受让方企业注册号'
          },
          {
            id: 'accusedAddress',
            placeholder: '受让方联系地址'
          },
          {
            id: 'accusedPhone',
            placeholder: '受让方电话号码'
          },
          {
            id: 'accusedEmail',
            placeholder: '受让方电子邮箱'
          },
          {
            id: 'accusedLegalRepresentative',
            placeholder: '受让方法定代表人'
          },
          {
            id: 'accusedAuthorizedAgent',
            placeholder: '受让方授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 10,
    label: '矿业权纠纷调解协议书',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '当事人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 11,
    label: '矿业权变更申请书',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '申请人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "changeReason",
            placeholder: "变更原因"
          },
          {
            id: "changeContent",
            placeholder: "变更内容"
          }
        ]
      }
    ]
  },
  {
    id: 12,
    label: '矿业权续期申请书',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '申请人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "permitNumber",
            placeholder: "原许可证编号"
          },
          {
            id: "renewalPeriod",
            placeholder: "续期期限"
          },
          {
            id: "renewalReason",
            placeholder: "续期原因"
          }
        ]
      }
    ]
  },
  {
    id: 13,
    label: '矿业权保留申请书',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '申请人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "retentionPeriod",
            placeholder: "保留期限"
          },
          {
            id: "retentionReason",
            placeholder: "保留原因"
          }
        ]
      }
    ]
  },
  {
    id: 14,
    label: '矿业权试采申请',
    inputs: [
      {
        label: '基本信息',
        value: [
          {
            id: 'name',
            placeholder: '申请人姓名'
          },
          {
            id: 'company',
            placeholder: '企业名称'
          },
          {
            id: 'id',
            placeholder: '身份证号'
          },
          {
            id: 'companyId',
            placeholder: '企业注册号'
          },
          {
            id: 'address',
            placeholder: '联系地址'
          },
          {
            id: 'phone',
            placeholder: '电话号码'
          },
          {
            id: 'email',
            placeholder: '电子邮箱'
          },
          {
            id: 'legalRepresentative',
            placeholder: '法定代表人'
          },
          {
            id: 'authorizedAgent',
            placeholder: '授权代理人'
          }
        ]
      },
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "testMiningMethod",
            placeholder: "试采方式"
          },
          {
            id: "testMiningPeriod",
            placeholder: "试采期限"
          },
          {
            id: "testMiningScale",
            placeholder: "试采规模"
          },
          {
            id: "testMiningPlan",
            placeholder: "试采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  },
  {
    id: 15,
    label: '矿业权注销证书',
    inputs: [
      {
        label: '其他信息',
        value: [
          {
            id: "location",
            placeholder: "矿区位置"
          },
          {
            id: "area",
            placeholder: "矿区面积"
          },
          {
            id: "oreType",
            placeholder: "矿种"
          },
          {
            id: "miningMethod",
            placeholder: "开采方式"
          },
          {
            id: "miningPeriod",
            placeholder: "开采期限"
          },
          {
            id: "miningScale",
            placeholder: "开采规模"
          },
          {
            id: "miningPlan",
            placeholder: "开采方案"
          },
          {
            id: "environmentProtectionMeasures",
            placeholder: "环境保护措施"
          }
        ]
      }
    ]
  }
]