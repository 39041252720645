<template>
  <div class="chat-wrap">
    <ul :class="['aside-nav', isAsideShow ? '' : 'hide']">
      <li :class="['nav-item', curNav === nav.value ? 'active' : '' ]" v-for="nav in navs" :key="nav.value" @click="handleNavClick(nav)">
        <svg class="icon" aria-hidden="true">
          <use :xlink:href="'#'+nav.icon"></use>
        </svg>
        {{ nav.name }}
      </li>
    </ul>

    <div class="chat-content">
      <div class="chat-list" ref="$chatList">
        <!-- 问题 - 用户发送 -->
        <template v-for="(message, index) in chatList">
          <div v-if="message.role === 'user'" class="message question" :key="index + 'question'">
            <div class="content file" v-if="message.content.file">
              <div class="date">{{ message.timestamp }}</div>
              <div class="text">
                <!-- <i class="iconfont icon-hetongshencha-morenbeifen"></i> -->
                <svg class="icon doc-icon" aria-hidden="true">
                  <use :xlink:href="fileType === 'doc' ? '#icon-word1' : '#icon-pdf'"></use>
                </svg>
                <div class="file-name">
                  {{ formateFileName(message.content.file) }}
                </div>
              </div>
            </div>
            <div v-else class="content">
              <div class="date">{{ formatTime(message.timestamp) }}</div>
              <div class="text">{{ message.content.text }}</div>
            </div>
            <div class="avatar">
              <img src="../../assets/user_avatar.png" alt="avatar">
            </div>
          </div>

          <!-- 答案 - 机器人回复 -->
          <div v-else-if="message.role === 'assistant'" class="message answer" :key="index + 'answer'">
            <div class="avatar">
              <img src="../../assets/ai_avatar.png" alt="avatar">
            </div>

            <div class="content">
              <div class="date">{{ formatTime(message.timestamp) }}</div>
              <div class="text">
                {{ message.content.text }}

                <div class="related-qestions" v-if="message.tips">
                  <div class="tip">以上回答满意吗？</div>
                  <el-button type="primary" plain @click="satisfied(message)">满意</el-button>
                  <el-button type="warning" plain @click="dissatisfied(message)">不满意</el-button>
                  <el-button type="success" plain @click="customerService(message)">联系客服</el-button>
                  <el-input class="input" type="textarea" v-show="message.showInput" v-model="message.feedback"
                            placeholder="请输入反馈内容"></el-input>
                  <el-button type="primary" round v-show="message.showInput"  @click="submitFeedback(message)">提交</el-button>
                  <div v-show="message.showConfirmation">{{ message.feedbackConfirmation }}</div>

                </div>

                <div class="cases" v-if="message.cases && message.cases.length">
                  <div class="case-card" v-for="(item, idx) in message.cases" :key="idx">
                    <h1 class="title">{{ item.title }}</h1>
                    <div class="content">
                      {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="input-box">
        <input class="input" placeholder="请输入您想咨询的矿业法律问题" v-model="question"
               @keydown.enter.prevent="sendMessage"/>
        <svg class="icon send-icon" aria-hidden="true" @click="sendMessage">
          <use xlink:href="#icon-fasong-moren"></use>
        </svg>
      </div>

      <div class="loading" v-show="loading">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div class="intro" v-if="isQuery(curNav) && chatList.length === 1">
        <div class="intro-wrap">
          <div class="intro-item">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-zhuanyekuangyefalvzixun"></use>
            </svg>
            <h1 class="title">专业矿业法律咨询</h1>
            <h1 class="subtitle">深度解读矿业法律难题，帮助您洞察市场，决策更精准</h1>
            <div class="desc-item">
              <span class="text">
                具备丰富的矿业法律知识，LegalMineGPT针对性地解答您的各类疑问，提供具有针对性的法律解答，助力您的业务决策
              </span>
            </div>
            <div class="desc-item">
              <span class="text">
                深度洞察矿业法律市场，实时提供相关政策变动、行业动态以及法律风险分析，使您在市场中始终保持领先
              </span>
            </div>
            <div class="desc-item">
              <span class="text">
                针对复杂的矿业法律问题，提供详尽的法律解释和建议，确保您在任何法律挑战面前，都能有应对策略
              </span>
            </div>
          </div>
          <div class="intro-item">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-AIjishuyinling"></use>
            </svg>
            <h1 class="title">AI技术引领</h1>
            <h1 class="subtitle">采用最新人工智能技术，理解和解决复杂法律问题，提供即时、准确的法律咨询</h1>
            <div class="desc-item">
              <span class="text">
              我们的AI技术基于大量矿业法律数据进行训练，提供专业且针对性的咨询服务，能够深度理解并解答您的法律问题
              </span>
            </div>
            <div class="desc-item">
              <span class="text">
              LegalMineGPT能够分析复杂的法律语境，准确理解并快速响应您的咨询需求，让法律咨询变得更加简单快捷
              </span>
            </div>
            <div class="desc-item">
              <span class="text">
              我们不断更新和优化AI技术，以确保我们的法律咨询服务始终处于行业前沿，满足您的不断变化的需求
              </span>
            </div>
          </div>
          <div class="intro-item">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-kuaisuxiangying"></use>
            </svg>
            <h1 class="title">快速响应</h1>
            <h1 class="subtitle">无论何时何地，只需点击几下，即可获取专业的法律指导，为您节省宝贵的时间</h1>
            <div class="desc-item">
              <span class="text">
              LegalMineGPT能够24/7为您提供服务，无论何时，只要有需求，我们都在这里为您提供帮助
              </span>
            </div>
            <div class="desc-item">
              <span class="text">
              只需几次点击，即可获取到您所需要的法律咨询，无需预约，无需等待，我们随时为您解答法律问题
              </span>
            </div>
            <div class="desc-item">
              <span class="text">
              我们理解时间的重要性，特别是对于矿业公司，LegalMineGPT的高效响应能够为您节省大量时间，让您有更多时间关注核心业务
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="intro contract" v-if="isContractReview(curNav) && chatList.length === 1">
        <div>
          <h1 class="intro-title">智能合同管理，以科技力量保障您的矿业权益</h1>
          <div class="intro-wrap">
            <div class="intro-item">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-hetongshencha"></use>
              </svg>
              <h1 class="title">合同审查</h1>
              <div class="desc-item">
                <span class="text">
                LegalMineGPT智能识别、定位合同中可能的风险点，为您提供详细的风险报告和改进建议，帮助您提前规避法律风险
                </span>
              </div>
              <div class="desc-item">
                <span class="text">
                通过大数据比对，LegalMineGPT能快速发现合同条款中的遗漏和冗余，确保合同的完整性和合法性
                </span>
              </div>
              <div class="desc-item">
                <span class="text">
                对矿业行业合同中的专业术语和行业规定有深入理解，为您提供符合矿业特性的合同审查服务
                </span>
              </div>
            </div>
            <div class="intro-item">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-xiaoshuaiyouhua"></use>
              </svg>
              <h1 class="title">效率优化</h1>
              <div class="desc-item">
                <span class="text">
                借助人工智能技术，LegalMineGPT大大提升了合同审查效率，将原本需要数小时甚至数天的工作量缩短为几分钟
                </span>
              </div>
              <div class="desc-item">
                <span class="text">
                LegalMineGPT实时提供合同审查反馈，帮助您及时了解合同审查进度，随时掌握工作动态
                </span>
              </div>
              <div class="desc-item">
                <span class="text">
                有了LegalMineGPT，您可以把更多的时间用于关注业务发展和市场变化，从容应对业务挑战
                </span>
              </div>
            </div>
            <div class="intro-item">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-zhuanyezhidao"></use>
              </svg>
              <h1 class="title">专业指导</h1>
              <div class="desc-item">
                <span class="text">
                LegalMineGPT对矿业合同的特殊性有深入理解，可以针对性地为您提供专业指导
                </span>
              </div>
              <div class="desc-item">
                <span class="text">
                LegalMineGPT实时关注矿业法律法规的最新变动，帮助您及时更新合同条款，符合最新法规要求
                </span>
              </div>
              <div class="desc-item">
                <span class="text">
                从合同起草、修改、审查到执行，LegalMineGPT提供全程专业服务，降低您的法律风险
                </span>
              </div>
            </div>
          </div>
          <button class="upload-btn" @click="isUploadDialogShow = true">上传</button>
        </div>
      </div>
    </div>

    <Dialog v-model="isUploadDialogShow">
      <div :class="['upload-box', { 'selected': selectedFile }]" @dragover.prevent @drop="handleDrop"
           @click="handleUploadBoxClick">
        <svg class="icon upload-icon" aria-hidden="true" v-if="!isFileSelected">
          <use xlink:href="#icon-shangchuanwenjian"></use>
        </svg>
        <template v-else>
          <svg class="icon doc-icon" aria-hidden="true">
            <use :xlink:href="fileType === 'doc' ? '#icon-word1' : '#icon-pdf'"></use>
          </svg>
        </template>
        <span class="tip">
          {{ isFileSelected ? selectedFile.name : '拖入或选择您要上传的合同，支持 word 和 pdf 格式'}}
        </span>
        <input class="requirement-input" v-show="isFileSelected" type="text" v-model="requirement" placeholder="请输入您的要求">
        <div class="upload-btn" v-show="isFileSelected" @click.stop="startUpload">立即上传</div>
        <div class="progress-bar" v-show="isProgressShow"> <!--  -->
          <div class="progress-fill" :style="{width: uploadProgress + '%'}"></div>
        </div>
      </div>
    </Dialog>

    <el-dialog v-model="IsCustomerService" title="联系客服" width="700">
       <div><el-tag type="info">问题</el-tag> <el-text class="mx-1">{{sendForm.question}}</el-text></div>
       <div><el-tag type="info">回答</el-tag> <el-text class="mx-1" type="primary">{{ sendForm.answer }}</el-text></div>
      <el-form :model="sendForm">
        <el-form-item label="您的邮件地址"  style="margin-top: 30px" required >
          <el-input type="text" v-model="sendForm.email" ></el-input>
        </el-form-item>
        <el-form-item label="您的联系电话"  style="margin-top: 30px">
          <el-input type="text" v-model="sendForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="您的意见"  style="margin-top: 30px">
          <el-input type="textarea" v-model="sendForm.msg"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="IsCustomerService = false">取消</el-button>
          <el-button class="el-button--primary" @click.stop="startSend">立即发送</el-button>
        </div>
        <el-tag v-show="sendMsgShow" type="danger" size="large">{{ sendMsgTop }}</el-tag>
      </template>

    </el-dialog>

    <input
        type="file"
        ref="$fileInput"
        style="display: none"
        accept=".pdf, .doc, .docx"
        @change="handleFileInput"
    />
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed, nextTick } from "vue"
import { useStore } from "vuex"
import { useRouter, useRoute } from 'vue-router'
import { createToaster } from "@meforma/vue-toaster";

import Dialog from '../../components/dialog.vue'

import { getCurDate, generateId, formatTime } from '../../utils/index'

import {uploadFile, gptQuery, feedback, sendMsg} from '../../request/index'
import { baseUrl } from '../../request/config'

const navs = [
  {
    name: '法律法规查询',
    value: 0,
    icon: 'icon-falvfaguichaxun-gaoliangbeifen'
  }, {
    name: '合同审查',
    value: 1,
    icon: 'icon-hetongshencha-morenbeifen'
  }, {
    name: '文书草拟',
    value: 2,
    icon: 'icon-wenshucaoni-morenbeifen'
  }
]

const formLabelWidth = '140px'

const sendMsgShow = ref(false)
const sendMsgTop = ref('')

const USER = 'user'
const ASSISTANT = 'assistant'
const DIR = 'test_dir'

const route = useRoute()
const router = useRouter()
const store = useStore()

// 联系客服变量
const isSendSuccess = ref(false)
const IsCustomerService = ref(false)

const sendForm = ref({
  email: '',
  phone: '',
  msg: '',
  question: '',
  answer: '',
})


let token = computed(() => store.state.token)
let isAsideShow = computed(() => store.state.isAsideShow)

const toaster = createToaster({
  position: "top"
});

let chatId = '',
  contractReviewChatId = '',
  curChatId = ''

onMounted(() => {
  console.log(route.query)
  const curNav = +route.query.cur
  // handleNavClick(navs[curNav])
  chatId = generateId()
  contractReviewChatId = generateId()
  curChatId = isQuery(curNav) ? chatId : contractReviewChatId
})
onBeforeUnmount(() => {
  chatController.abort();
  contractReviewController.abort();
});

// --------- nav -------------
const isQuery = (curNav) =>{
  return curNav === 0
}

const isContractReview = (curNav) =>{
  return curNav === 1
}

const curNav = ref(0)
const handleNavClick = (nav) => {
  chatController.abort()
  contractReviewController.abort();

  chatController = new AbortController()
  contractReviewController = new AbortController()
  console.log(navs)
  console.log(nav.value)
  if (nav.name === '文书草拟') {
    const to = router.resolve('/drafting')
    window.open(to.href, '_blank')
    return
  }
  if (nav.name === '合同审查' && window.innerWidth < 800 ) {
    isUploadDialogShow.value = true
  }
  curNav.value = nav.value
  curChatId = isQuery(curNav.value) ? chatId : contractReviewChatId
  chatList.value = [{
    role: ASSISTANT,
    content: {
      text: '为矿业法律照亮前行之路，挖掘未知的可能。您好！欢迎使用LegalMineGPT。我们以人工智能技术引领矿业法律咨询，为您提供专业而周全的解答。有什么可以帮助到您？'
    },
    timestamp: Date.now(),
    message_id: generateId()
  }]

  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (screenWidth < 1024) {
    store.commit('setIsAsideShow', false)
  }
}
// ---------------------------

// 聊天列表滚动到底部
const scrollToBottom = () => {
  $chatList.value.scrollTop = $chatList.value.scrollHeight
}

// --------- 聊天提问 ------------
const $chatList = ref(null),
  chatList = ref([{
    role: ASSISTANT,
    content: {
      text: '为矿业法律照亮前行之路，挖掘未知的可能。您好！欢迎使用LegalMineGPT。我们以人工智能技术引领矿业法律咨询，为您提供专业而周全的解答。有什么可以帮助到您？'
    },
    timestamp: Date.now(),
    message_id: generateId(),
    tips: false,
    showInput: false,
    showConfirmation: false,
    feedbackConfirmation: "",
    feedback: ''
  }]),
  question = ref(''),
  loading = ref(false)

let chatController = new AbortController();
const sendMessage = () => {
  if (loading.value) return
  if (!question.value) return

  // push 问题
  chatList.value.push({
    role: USER,
    // message: question.value,
    content: {
      text: question.value
    },
    timestamp: Date.now(),
    message_id: generateId()
  })
  nextTick(() => {
    scrollToBottom()
  })

  // 获取 ai 回答
  loading.value = true
  gptQuery({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token.value
    },
    signal: chatController.signal,
    body: JSON.stringify({
      chatid: curChatId,
      cmd: "chat",
      temperature: 0.5,
      desire_word_num: 500,
      messages: chatList.value
    })
  }).then(async (response) => {
    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    let partialLine = '';
    const msgObj = ref({
      role: ASSISTANT,
      // message: '',
      content: {
        text: ''
      },
      timestamp: '',
      message_id: '',
      tips: false,
      showInput: false,
      showConfirmation: false,
      feedbackConfirmation: "",
      feedback: '',
      is_good: true,
    })
    chatList.value.push(msgObj.value)
    async function read() {
      const { done, value } = await reader.read();
      if (done) {
        if (partialLine) {
          console.log(partialLine);
        }
        loading.value = false
        return;
      }
      const lines = decoder.decode(value, { stream: true }).split('\n');
      lines[0] = partialLine + lines[0];
      partialLine = lines.pop();

      for (const line of lines) {
          console.log("----------------------------")
          console.log(line);

          try {
            const valueObj = JSON.parse(line)
            const text = valueObj.message.content.text
            if (text !== '[start_info_of_msg]' && text !== '[end_info_of_msg]' && !text.includes('used_model')) {
              msgObj.value.content.text += valueObj.message.content.text
            }
            if (!msgObj.value.timestamp) {
              msgObj.value.timestamp = valueObj.message.timestamp
            }
            if (!msgObj.value.message_id) {
              msgObj.value.message_id = valueObj.message.message_id
            }

            scrollToBottom()
          } catch(e) {
            console.log('JSON 转换错误了，请检查返回数据格式：',e)
          }
      }


      return read();
    }

    await read();
    msgObj.value.tips = true
    console.log(msgObj.value)

  }).catch((err) => {
    console.error(err)
    loading.value = false
  });

  // 清空文本框
  question.value = ''
}
// ----------------------

// ---------- 合同审查 ------------
const isUploadDialogShow = ref(false),
  fileType = ref(''),
  isFileSelected = ref(false),
  selectedFile = ref(null),
  uploadProgress = ref(0),
  isProgressShow = ref(false),
  $fileInput = ref(null),
  requirement = ref('请帮我审查这份合同')
let contractReviewController = new AbortController()

const isDoc = (file) => {
  return file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
}
const isPdf = (file) => {
  return file.type === 'application/pdf'
}
const formateFileName = (path) => {
  return path.replace(`${DIR}/`, '')
}
const selectFile = (file) => {
  selectedFile.value = file
  isFileSelected.value = true
  if (isDoc(file)) {
    fileType.value = 'doc'
  } else if (isPdf(file)) {
    fileType.value = 'pdf'
  }
}
const handleDrop = (event) => {
  event.preventDefault();
  const files = event.dataTransfer.files;
  if (files.length > 1) {
    alert('只能选择一个文件');
    return
  } else {
    const file = files[0]
    if (!isDoc(file) && !isPdf(file)) {
      alert('只能上传Word文档或PDF文件');
      return
    }
    selectFile(file)
  }
}
const handleFileInput = (event) => {
  const files = event.target.files;
  const file = files[0]
  selectFile(file)
}
const handleUploadBoxClick = () => {
  $fileInput.value.click()
}
const satisfied = (message) => {
  message.showInput = false
  message.showConfirmation = true
  message.feedbackConfirmation = "感谢您的反馈!"
  message.is_good = true
  submitFeedback(message)
}

const dissatisfied = (message) => {
  message.showInput = false
  message.showConfirmation = true
  message.showInput = true
  message.is_good = false
}
const customerService = (message) => {
  for (let i=0; i < chatList.value.length; i++){
    if (chatList.value[i].message_id === message.message_id){
      sendForm.value.question = chatList.value[i-1].content.text
    }
  }
  sendForm.value.answer = message.content.text
  IsCustomerService.value = true

}

const submitFeedback = (message) => {
  console.log("chatlist=====>", chatList.value)
  feedback({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token.value
    },
    signal: chatController.signal,
    body: JSON.stringify({
      chatid: curChatId,
      cmd: "chat",
      messages: chatList.value
    })
  }).then(async (response) => {
    message.showConfirmation = true
    message.feedbackConfirmation = "感谢您的反馈，我们会及时处理。"
    message.showInput = false
  }).catch((err) => {
      console.error(err)
      message.showConfirmation = true
      message.feedbackConfirmation = "反馈失败，请稍后再试。"
  });
}
const startSend = () => {
  if (sendForm.value.email === "" || sendForm.value.email === null){
    sendMsgShow.value = true;
    sendMsgTop.value = "邮件地址不能为空";
  } else {
    const formData = new FormData();
    formData.append("email", sendForm.value.email)
    formData.append("phone", sendForm.value.phone)
    formData.append("msg", sendForm.value.msg)
    formData.append("question", sendForm.value.question)
    formData.append("answer", sendForm.value.answer)
    sendMsg(formData).then(async (response) => {
        isSendSuccess.value = true
        sendMsgTop.value = "发送成功！"
        sendMsgShow.value = false
    }
    ).catch((err) => {
        console.error(err)
        sendMsgShow.value = true;
        sendMsgTop.value = "发送失败，请重试！";
    }
    )
  }

}
const startUpload = () => {
  uploadProgress.value = 0
  isProgressShow.value = true

  const formData = new FormData();
  formData.append('files', selectedFile.value);
  // formData.append('SaveDir', DIR);
  uploadFile(formData, (progressEvent) => {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    uploadProgress.value = progress;
    // if (progress === 100) {
    // }
  }).then(res => {
    isUploadDialogShow.value = false
    isProgressShow.value = false
    chatList.value.push({
      role: USER,
      // message: null,
      content: {
        text: requirement.value,
        file: `${DIR}/${selectedFile.value.name}`,
      },
      // fileType: fileType.value,
      timestamp: Date.now(),
      message_id: generateId()
    }/* , {
      role: USER,
      // message: '合同上传成功',
      content: {
        text: '合同上传成功'
      },
      timestamp: getCurDate(),
      message_id: generateId()
    } */)

    loading.value = true
    gptQuery({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token.value
      },
      signal: contractReviewController.signal,
      body: JSON.stringify({
        chatid: curChatId,
        cmd: "contract_review",
        temperature: 0.5,
        desire_word_num: 500,
        messages: chatList.value
      })
    }).then(async (response) => {
      const reader = response.body.getReader()
      const decoder = new TextDecoder('utf-8')
      let partialLine = '';

      const msgObj = ref({
        role: ASSISTANT,
        // message: '',
        content: {
          text: ''
        },
        timestamp: '',
        message_id: ''
      })
      chatList.value.push(msgObj.value)

      async function read() {
        const { done, value } = await reader.read();
        if (done) {
          if (partialLine) {
            console.log(partialLine);
          }
          loading.value = false
          return;
        }
        const lines = decoder.decode(value, { stream: true }).split('\n');
        lines[0] = partialLine + lines[0];
        partialLine = lines.pop();

        for (const line of lines) {
            console.log("----------------------------")
            console.log(line);

            try {
              const valueObj = JSON.parse(line)
              const text = valueObj.message.content.text
              if (text !== '[start_info_of_msg]' && text !== '[end_info_of_msg]' && !text.includes('used_model')) {
                msgObj.value.content.text += valueObj.message.content.text
              }
              if (!msgObj.value.timestamp) {
                msgObj.value.timestamp = valueObj.message.timestamp
              }
              if (!msgObj.value.message_id) {
                msgObj.value.message_id = valueObj.message.message_id
              }
              scrollToBottom()
            } catch(e) {
              console.log('JSON 转换错误了，请检查返回数据格式：',e)
            }
        }

        return read();
      }

      await read();

      /* return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                loading.value = false
                return;
              }
              const valueDecode = new TextDecoder().decode(value);
              try {
                const valueObj = JSON.parse(valueDecode)
                if (valueObj.message.content.text !== '[start_info_of_msg]' && valueObj.message.content.text !== '[end_info_of_msg]') {
                  msgObj.value.content.text += valueObj.message.content.text
                }
                if (!msgObj.value.timestamp) {
                  msgObj.value.timestamp = valueObj.message.timestamp
                }
                if (!msgObj.value.message_id) {
                  msgObj.value.message_id = valueObj.message.message_id
                }
                scrollToBottom()
              } catch(e) {
                console.log(valueDecode);
                console.log('JSON 转换错误了，请检查返回数据格式：',e)
              }
              controller.enqueue(value);
              return pump();
            });
          }
        },
      }); */
    })
    .catch((err) => {
      console.error(err)
      loading.value = false
    });

    isFileSelected.value = false
    selectedFile.value = null
    $fileInput.value.value = ''
  }).catch((err) => {
    console.log(err)
    toaster.error('上传失败！')
  }).finally(() => {
    uploadProgress.value = 0
    isProgressShow.value = false
  })
}
// -----------------------
</script>

<style lang="less" scoped>
.chat-wrap {
  display: flex;
  height: calc(100vh - 93px);

  .aside-nav {
    // position: ;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 304px;
    width: 304px;
    border-right: 1px solid #dbdbdb;
    height: 100%;
    transition: all .4s;
    overflow: hidden;
    background: #202124;
    z-index: 99;

    &.hide {
      width: 0;
      flex-basis: 0;
      opacity: 0;
    }

    .nav-item {
      display: flex;
      align-items: center;
      width: 249px;
      height: 48px;
      border: 1px solid transparent;
      color: #e3e6e9;
      border-radius: 4px;
      margin-top: 14px;
      cursor: pointer;

      &.active {
        border-color: #f5af6c;
        color: #f5af6c;
        font-weight: 500;
        // background-color: #fffaf5;
        .icon {
          color: #f5af6c;
        }
      }

      .icon {
        width: 18px;
        height: 18px;
        padding: 0 10px;
        color: #fff;
        margin-top: -2px;
      }
    }
  }
}

.chat-content {
  position: relative;
  flex: 1;
  padding: 0 40px;
  width: 100%;
  max-width: 1120px;
  margin: 30px auto 0;

  .chat-list {
    height: 92%;
    overflow: auto;
    padding-bottom: 20px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .loading {
    position: absolute;
    bottom: 12%;
    left: 50%;
    width: 78px;
    transform: translateX(-39px);
  }

  .input-box {
    position: relative;
    height: 6%;
    box-sizing: border-box;

    .input {
      width: 100%;
      height: 100%;
      padding: 6px;
      box-sizing: border-box;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      outline-color: #F19135;
    }
    .send-icon {
      position: absolute;
      right: 16px;
      top: 50%;
      font-size: 20px;
      transform: translate3d(0, -50%, 0);
      cursor: pointer;
    }
  }

  .intro {
    position: absolute;
    left: 39px;
    right: 39px;
    top: -1px;
    bottom: 10%;
    background: #fff;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    &.contract {
      display: flex;
      align-items: center;
      .intro-wrap {
        height: auto;
      }
      .intro-title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #f19135;
        margin-bottom: 40px;
      }
      .intro-item {
        .title {
          margin-bottom: 24px;
        }
      }
      .upload-btn {
        width: 120px;
        height: 40px;
        background: #f19135;
        color: #fff;
        border-radius: 3px;
        font-size: 14px;
        cursor: pointer;
        border: 0;
        margin: 10px 0 0 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }


    .intro-wrap {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .intro-item {
      flex: 1;
      max-width: 380px;
      margin-right: 18px;

      .icon {
        font-size: 28px;
        margin-left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
      .title {
        font-size: 22px;
        color: #1D1E22;
        text-align: center;
        margin: 20px 0;
        font-weight: bold;
      }
      .subtitle {
        width: 90%;
        height: 72px;
        font-size: 14px;
        text-align: center;
        color: #1d1e22;
        margin: 0 auto;
        line-height: 24px;
        // margin-bottom: 18px;
      }
      .desc-item {
        width: 100%;
        height: 108px;
        background: #f6f6f6;
        border-radius: 6px;
        margin-bottom: 18px;
        padding: 16px 26px;
        box-sizing: border-box;
        word-break: break-all;
        font-size: 14px;
        line-height: 24px;
        .text {
          display: inline-block;
          width: 100%;
          height: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
        }

        // overflow: auto;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
      }
    }
  }
}

.message {
  display: flex;
  margin-bottom: 10px;

  &.question {
    justify-content: flex-end;
    .content {
      margin-right: 10px;
      .date {
        text-align: right;
      }
      .text {
        background: #f19135;
        color: #fff;
        float: right;
      }
    }
  }
  &.answer {
    justify-content: flex-start;
    .content {
      margin-left: 10px;
      .text {
        background: #f6f6f6;
      }
    }
  }

  .content {
    max-width: 80%;

    &.file {
      .icon {
        font-size: 60px;
      }
      .text {
        text-align: center;
        // background: #fff;

        .file-name {
          margin-top: 6px;
          font-size: 14px;
          // color: #2e2e2e;
        }
      }
    }

    .date {
      font-size: 16px;
      color: #b6b6b6;
    }
    .text {
      display: inline-block;
      padding: 12px;
      font-size: 18px;
      line-height: 26px;
      word-break: break-all;
      margin-top: 6px;
      border-radius: 6px;
      color: #1d1e22;
      white-space: pre-wrap;
    }
  }
  .avatar {
    width: 36px;
    height: 36px;
    // border: 1px solid #eee;
    // border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .related-qestions {
    margin-top: 20px;

    .tip {
      font-weight: bold;
    }

    .qestion-list {
      margin-top: 10px;
      .question-item {
        color: #f19135;
        margin-top: 6px;
      }
    }
  }

    .case-card {
      margin-top: 10px;
      display: inline-block;
      background: #fff;
      padding: 10px;
      border-radius: 6px;
      cursor: pointer;

      .title {
        font-size: 20px;
      }
      .content {
        font-size: 17px;
        color: #717171;
        line-height: 28px;
      }
    }
}

.upload-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 510px;
  border: 1px dotted #f19135;
  border-radius: 12px;
  padding: 50px 0;

  &.selected {
    width: 400px;
    // padding: 40px 0;

    .tip {
      margin-top: 10px;
    }
  }

  .upload-icon {
    font-size: 38px;
    color: #F19135;
  }
  .doc-icon {
    font-size: 58px;
  }
  .tip {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #1d1e22;
    padding: 0 4px;
  }

  .requirement-input {
    margin: 30px 0;
    outline: none;
    height: 36px;
    width: 86%;
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    padding: 0 4px;
  }

  .send-msg-input {
    margin-top: 100px;
    outline: none;
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    padding: 0 4px;
    display: inline-block;

  }

  .upload-btn {
    padding: 6px 12px;
    background: #f19135;
    color: #fff;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  }

  .progress-bar {
    position: absolute;
    bottom: 4px;
    height: 6px;
    width: 96%;
    background: rgb(228, 228, 228);
    border-radius: 4px;

    .progress-fill {
      height: 100%;
      width: 0;
      background: #00a531;
      border-radius: 4px;
      transition: all .4s;
    }
  }
}

// loading
.lds-ellipsis {
  display: inline-block;
  position: relative;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #9b9b9b;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media screen
  and (max-device-width: 1024px) {
  .chat-content {
    padding: 0 20px;
    .intro {
      left: 19px;
      right: 19px;
    }
  }
  .chat-wrap {
    .aside-nav {
      position: absolute;
      height: calc(100% - 96px);
    }
  }
  .upload-box {
    width: 490px;
  }
}
@media screen
  and (max-device-width: 800px) {
    .chat-content {
      .intro {
        display: none !important;
      }
    }
}

@media screen
  and (max-device-width: 678px) {
  .upload-box {
    width: 300px;

    &.selected {
      width: 290px;
    }

    .tip {
      font-size: 14px;
      margin: 20px 20px 0;
    }
  }
}

@media screen
  and (max-device-height: 740px) {
  .chat-content {
    .input-box {
      height: 8%;
    }
  }
}
@media screen
  and (max-device-height: 678px) {
  .message {
    .content {
      .date {
        font-size: 12px;
      }
      .text {
        padding: 10px;
        font-size: 15px;
      }
    }
  }
}
</style>