<template>
  <div class="manage-doc" v-loading.fullscreen.lock="uploadLoading">
    <div class="top">
      <el-button type="warning" @click="handleUploadClick">上传文档</el-button>

      <div>
        <el-button type="warning" @click="handleExecuteClick" :disabled="!selectedFiles.length">向量化处理</el-button>
        <!--        <el-button type="primary" @click="handleExecuteDownload" :disabled="selectedFiles.length!==1">下载文件</el-button>-->
        <el-button type="danger" @click="delFilesClick" :disabled="!selectedFiles.length">删除文件</el-button>
      </div>
    </div>

    <el-table
        v-loading="getFileDataLoading"
        :data="docData.file_list"
        style="width: 100%; margin-top: 10px;"
        @selection-change="handleSelectionChange"
        height="76vh"
    >
      <el-table-column type="selection" width="55"/>
      <el-table-column
          prop="file_name"
          label="文件名" :show-overflow-tooltip="true" width="400">
      </el-table-column>
      <el-table-column
          prop="file_hash"
          label="文件哈希值" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
          prop="size"
          label="文件大小"
          width="100">
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span style="margin-left: 10px">{{ formatStatus(scope.row.status) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="add_time"
          label="上传时间"
          width="150" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
          prop="update_time"
          label="更新时间"
          width="150" :show-overflow-tooltip="true">
      </el-table-column>
    </el-table>

    <el-pagination
        background
        style="float:right; margin-top: 20px;"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 50, 100, 200]"
        :total="docData.total"
        v-model:current-page="page"
        :page-size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />

    <input
        type="file"
        ref="$fileInput"
        style="display: none"
        multiple
        accept=".pdf, .doc, .docx"
        @change="handleFileInput"
    />

    <Dialog v-model="uploadStatusDialogShow">
      <div class="upload-status-box">
        <div class="title">输入要求</div>

        <ul class="status-list">
          <li class="status-item" v-for="(item, index) in uploadStatusData" :key="index">
            <div class="name">{{ item.name }}</div>
            <div class="status">
              {{ statusMap[item.status] }}
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="iconMap[item.status]"></use>
              </svg>
            </div>
          </li>
        </ul>

        <div class="btn" @click="uploadStatusDialogShow = false">确定</div>
      </div>
    </Dialog>

    <Dialog v-model="executeDialogShow">
      <div class="execute-box">
        <div class="title">处理</div>

        <div class="config-form">
          <!-- <div class="chatgpt-models">
            <el-card class="box-card" v-for="(value, key) in configurationData.chatgpt_models" :key="key">
              <template #header>
                <div class="card-header">
                  <label>{{ key }} </label>
                  <el-checkbox v-model="value.default" @change="handleCbChange($event, key)" />
                </div>
              </template>
              <div class="form-item">
                <div class="label">chatgpt_max_tokens：</div>
                <el-input-number style="margin-top: 6px; width: 100%;" v-model="value.chatgpt_max_tokens" controls-position="right"/>
              </div>
              <div class="form-item">
                <div class="label">token_cost_perk：</div>
                <el-input-number style="margin-top: 6px; width: 100%;" v-model="value.token_cost_perk" controls-position="right"/>
              </div>
              <div class="form-item">
                <div class="label">endpoint：</div>
                <el-input style="margin-top: 6px" v-model="value.endpoint" />
              </div>
            </el-card>
          </div> -->
          <el-card>
            <div class="order-config">
              <!-- <div class="form-item">
                <div class="label">temperature：</div>
                <el-input-number style="margin-top: 6px; width: 100%;" v-model="configurationData.temperature" controls-position="right"/>
              </div> -->
              <div class="form-item">
                <div class="label">知识库名称：</div>
                <el-input style="margin-top: 6px;" placeholder="请输入" v-model="kbName"/>
              </div>
              <div class="form-item">
                <div class="label">embedding_model：</div>
                <el-input style="margin-top: 6px;" v-model="configurationData.embedding_model"/>
              </div>
              <div class="form-item">
                <div class="label">token_encoder_model：</div>
                <el-input style="margin-top: 6px" v-model="configurationData.token_encoder_model"/>
              </div>
              <div class="form-item">
                <div class="label">section_max_length：</div>
                <el-input-number style="margin-top: 6px; width: 100%;" v-model="configurationData.section_max_length"
                                 controls-position="right"/>
              </div>
            </div>
          </el-card>
        </div>

        <div class="btns">
          <div v-loading="resetLoading" class="btn reset" style="margin-right: 10px;" @click="resetConfigurationSubmit">
            重置配置
          </div>
          <div v-loading.fullscreen.lock="executeLoading"
               element-loading-text="努力处理中，可能会花费几分钟时间，请耐心等待..." class="btn"
               @click="executeSubmit">开始处理
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import {ref, watch, computed, onMounted} from 'vue'
import Dialog from '../../components/dialog.vue'
import {formatStatus} from "../../utils/format";

import {
  uploadFile,
  getFiles,
  delFiles,
  getConfiguration,
  resetConfiguration,
  setConfiguration,
  processData, addKb, downloadFile
} from '../../request/index'

import {ElMessageBox} from 'element-plus'
import {createToaster} from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top"
});

const docData = ref([]),
    getFileDataLoading = ref(false),
    page = ref(1),
    size = ref(10)
const getFileData = () => {
  getFileDataLoading.value = true
  getFiles({
    page: page.value, // 后端 page 从 0 开始
    per_page: size.value
  }).then(res => {
    docData.value = res.data
  }).finally(() => {
    getFileDataLoading.value = false
  })
}
const handleSizeChange = (v) => {
  page.value = 1
  size.value = v
  getFileData()
}
const handleCurrentChange = (v) => {
  page.value = v
  getFileData()
}

const configurationData = ref({})
let originalConfigData = {}
const getConfigurationData = () => {
  isConfigurationDataChange = false
  getConfiguration().then(({data}) => {
    configurationData.value = data.settings.openai_config
    originalConfigData = data.settings
    console.log(configurationData.value)
  })
}

const resetLoading = ref(false)
const resetConfigurationSubmit = () => {
  resetLoading.value = true
  isConfigurationDataChange = false
  resetConfiguration().then(({data}) => {
    configurationData.value = data.settings.openai_config
    console.log(configurationData.value)
    toaster.success('重置成功')
  }).finally(() => {
    resetLoading.value = false
  })
}

const handleCbChange = (v, key) => {
  Object.keys(configurationData.value.chatgpt_models).forEach(itemKey => {
    if (itemKey !== key) {
      configurationData.value.chatgpt_models[itemKey].default = false
    }
  })
}

let isConfigurationDataChange = false
const executeLoading = ref(false),
    kbName = ref('')
const executeSubmit = async () => {
  executeLoading.value = true
  const pathArr = selectedFiles.value.map(v => v.path)
  addKb().then(res => {
    // console.log('处理成功')
    // toaster.success('处理成功')
    executeLoading.value = false
    executeDialogShow.value = false
    ElMessageBox.alert(res.data.msg, '提示', {
      confirmButtonText: '我知道了',
      callback: () => {
      },
    })
  }).finally(() => {
  })
}

const executeDialogShow = ref(false)
const handleExecuteClick = () => {
  if (selectedFiles.value.length === 0) {
    ElMessageBox.alert('请选择文件')
  }
  executeLoading.value = true
  addKb({"ids": selectedFiles.value}).then(res => {
    executeLoading.value = false
    getFileData()
    if (res.data.code == 1) {
      toaster.error(res.data.msg)
    } else {
      toaster.success('向量化成功！')
    }


  }).catch(e => {
    console.log(e)
    executeLoading.value = false
    toaster.error('处理失败')
  })
}

const handleExecuteDownload = () => {
  if (selectedFiles.value.length !== 1) {
    ElMessageBox.alert('仅支持单文件下载')
  }
  downloadFile(selectedFiles.value[0])
}

let selectedFiles = ref([])
const handleSelectionChange = (v) => {
  selectedFiles.value = []
  for (let i = 0; i < v.length; i++) {
    selectedFiles.value.push(v[i].id)
  }
  console.log(selectedFiles.value)
}

const $fileInput = ref(null)
const handleUploadClick = () => {
  $fileInput.value.click()
}
const uploadLoading = ref(false),
    uploadStatusDialogShow = ref(false),
    uploadStatusData = ref([])
const handleFileInput = (event) => {
  const files = event.target.files;
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    const item = files[i]
    formData.append('files', item);
  }
  uploadLoading.value = true
  // uploadStatusData.value = []
  uploadFile(formData).then(res => {
    console.log(res)
    getFileData()
    // uploadStatusData.value = res.data
    // uploadStatusDialogShow.value = true
    if (res.data.code == 1) {
      toaster.error(res.data.msg)
    } else {
      toaster.success('上传成功')
    }

  }).catch(e => {
    console.log(e)
    executeLoading.value = false
    toaster.error(e)
  }
  ).finally(() => {
    uploadLoading.value = false
    $fileInput.value.value = ''
  })
}
const statusMap = ['上传失败', '上传成功', '文件已存在']
const iconMap = ['#icon-shangchuanshibai', '#icon-shangchuanchenggong', '#icon-shangchuanchenggong']

const delFilesClick = () => {
  ElMessageBox.confirm(
      '确定删除吗？',
      '警示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
  ).then(() => {
    delFiles(selectedFiles.value).then(res => {
      toaster.success('删除成功')
      getFileData()
    })
  })
}

watch(configurationData, (newVal, oldVal) => {
  if (JSON.stringify(oldVal) !== '{}') {
    console.log('configurationData 发生了变化:', newVal, oldVal);
    isConfigurationDataChange = true
  }
}, {deep: true});

onMounted(() => {
  getFileData()
  getConfigurationData()
})
</script>

<style lang="less" scoped>
.manage-doc {
  padding: 2%;

  .top {
    display: flex;
    justify-content: space-between;
  }

  .execute-box, .upload-status-box {
    padding: 0 20px;
    box-sizing: border-box;

    .title {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #1d1e22;
      padding: 10px 0 20px;
      border-bottom: 1px solid rgba(213, 213, 213, 0.35);
    }

    .btn {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #f19135;
      color: #fff;
      box-sizing: border-box;
      margin-top: 10px;
      border-radius: 6px;
    }
  }

  .execute-box {
    position: relative;
    width: 36vw;
    height: 350px;
    min-width: 700px;
    overflow: auto;

    .config-form {
      .order-config, .chatgpt-models {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }

      .chatgpt-models {
        margin-bottom: 6px;

        .box-card {
          .card-header {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: bold;

            .el-checkbox {
              height: auto;
            }
          }

          .form-item {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .form-item {
        margin-bottom: 10px;

        .label {
          font-size: 16px;
          color: #1d1e22;
        }

        .input {
          width: 95%;
          height: 30px;
          border: 1px solid #d5d5d5;
          border-radius: 6px;
          margin-top: 10px;
          // padding: 1px 6px;
          outline: none;
        }
      }
    }

    .btns {
      display: flex;
      position: absolute;
      bottom: 10px;
      width: 95%;

      .btn {
        flex: 1;
      }

      .reset {
        background: #fff;
        color: #606266;
        border: 1px solid #dcdfe6;
      }
    }
  }

  .upload-status-box {
    width: 520px;
    height: 500px;
    padding: 0 20px;
    box-sizing: border-box;

    .status-list {
      height: 76%;
      overflow: auto;

      .status-item {
        display: flex;
        justify-content: space-between;
        padding: 14px 0;
        border-bottom: 1px solid #d5d5d5;
        font-size: 14px;

        .name {
          width: 76%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        .status {
          width: 20%;
          text-align: right;
          color: rgba(29, 30, 34, 0.40);
        }
      }
    }
  }
}
</style>