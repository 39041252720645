import axios from 'axios';
import store from '../store'
import router from '../router'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({
  position: "top"
});

// export const baseUrl = 'http://47.253.87.43/api/v1'
export const baseUrl = '/api/v1'


const api = axios.create({
  baseURL: baseUrl,
  // timeout: 5000,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

api.interceptors.request.use(
  config => {
    if (localStorage.token) {
        config.headers['Authorization'] = localStorage.token;
    }
    return config
  },
  err => {
    console.log('请求错误了:', err);
    return Promise.reject(err)
  })

api.interceptors.response.use(
  response => {
    // console.log(response)
    return response
  },
  error => {
    console.log('拦截err：', error)
    if (error.response && error.response.status === 401) {
      // 清空 token
      store.commit('clearToken');
      toaster.warning('请重新登录！')
    }

    return Promise.reject(error);
  }
);
export default api;