<template>
  <div class="manage-model">
    <el-button type="warning" @click="addModelHandler">添加模型</el-button>
    <div class="model-wrap">
      <div class="model-item" @click="setModel('consult_query_model', '法律法规查询模型配置')">
        <div class="icon-wrap">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-falvfaguichaxun-gaoliangbeifen"></use>
          </svg>
        </div>
        <div class="title-wrap">
          <div class="title">法律法规查询</div>
          <div class="sub-title">算法模型</div>
        </div>
      </div>
      <div class="model-item" @click="setModel('contract_review_model', '合同审查模型配置')">
        <div class="icon-wrap">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-hetongshencha-morenbeifen"></use>
          </svg>
        </div>
        <div class="title-wrap">
          <div class="title">合同审查</div>
          <div class="sub-title">算法模型</div>
        </div>
      </div>
      <div class="model-item" @click="setModel('contract_draft_model', '文书草拟模型配置')">
        <div class="icon-wrap">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-wenshucaoni-morenbeifen"></use>
          </svg>
        </div>
        <div class="title-wrap">
          <div class="title">文书草拟</div>
          <div class="sub-title">算法模型</div>
        </div>
      </div>
      <div class="model-item" @click="setModel('contract_optimize_model', '文案优化模型配置')">
        <div class="icon-wrap">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-wenshucaoni-morenbeifen"></use>
          </svg>
        </div>
        <div class="title-wrap">
          <div class="title">文案优化</div>
          <div class="sub-title">算法模型</div>
        </div>
      </div>
    </div>

    <Dialog v-model="addModelDialogShow">
      <el-card class="box-card" style="margin: 20px;">
        <template #header>
          <div class="card-header">
            <el-input v-model="addModelName" placeholder="模型名称"></el-input>
          </div>
        </template>
        <div class="form-item">
          <div class="label">chatgpt_max_tokens：</div>
          <el-input-number style="margin-top: 6px; width: 100%;" v-model="addModelForm.chatgpt_max_tokens" controls-position="right"/>
        </div>
        <div class="form-item">
          <div class="label">token_cost_perk：</div>
          <el-input-number style="margin-top: 6px; width: 100%;" v-model="addModelForm.token_cost_perk" controls-position="right"/>
        </div>
        <div class="form-item">
          <div class="label">endpoint：</div>
          <el-input style="margin-top: 6px" v-model="addModelForm.endpoint" placeholder="endpoint"/>
        </div>
      </el-card>
      <el-button class="add-model-btn" type="warning" @click="addModelSubmit" :loading="addLoading">添加</el-button>
    </Dialog>

    <Dialog v-model="executeDialogShow">
      <div class="execute-box" v-loading="delModelLoading">
        <div class="title">{{curTitle}}</div>

        <div style="overflow: auto; height: 80%">
          <div class="config-form">
            <div class="chatgpt-models">
              <el-card class="box-card" v-for="(value, key) in curConfig.chatgpt_models" :key="key">
                <template #header>
                  <div class="card-header">
                    <label>{{ key }}  <el-button type="danger" text style="margin-top: -2px;" @click="delModel(key)">删除</el-button></label>
                    <el-checkbox v-model="value.default" @change="handleCbChange($event, key)" />
                  </div>
                </template>
                <div class="form-item">
                  <div class="label">chatgpt_max_tokens：</div>
                  <el-input-number style="margin-top: 6px; width: 100%;" v-model="value.chatgpt_max_tokens" controls-position="right"/>
                </div>
                <div class="form-item">
                  <div class="label">token_cost_perk：</div>
                  <el-input-number style="margin-top: 6px; width: 100%;" v-model="value.token_cost_perk" controls-position="right"/>
                </div>
                <div class="form-item">
                  <div class="label">endpoint：</div>
                  <el-input style="margin-top: 6px" v-model="value.endpoint" />
                </div>
              </el-card>
            </div>
          </div>

          <div class="form-item">
            <div class="label">temperature：</div>
            <el-input-number style="margin-top: 6px; width: 100%;" v-model="curConfig.temperature" controls-position="right" :min="0" :max="1" :precision="1"/>
          </div>
        </div>

        <div class="btn" @click="save" :loading="saveLoading">保存</div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'

import { getConfiguration, setConfiguration } from '../../request/index'

import Dialog from '../../components/dialog.vue'

import { ElMessageBox } from 'element-plus'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({
  position: "top"
});

let configData = {}
const modelData = ref({})
const getPromptData = () => {
  getConfiguration().then(({ data }) => {
    console.log(data);
    configData = data.settings
    modelData.value = data.settings.openai_config
  })/* .finally(() => {
    tableLoading.value = false
  }) */
}

// 添加
const addModelDialogShow = ref(false),
  addModelName = ref(''),
  addModelForm = ref({
    chatgpt_max_tokens: 0,
    token_cost_perk: 0,
    default: false,
    endpoint: '',
  })
const addModelHandler = () => {
  addModelDialogShow.value = true
}
const addLoading = ref(false)
const addModelSubmit = () => {
  if (!addModelName.value) {
    toaster.warning('请输入模型名称')
    return
  }
  if (configData.openai_config.chatgpt_models[addModelName.value]) {
    toaster.warning('该模型已存在，请修改名称')
    return
  }
  configData.openai_config.chatgpt_models[addModelName.value] = addModelForm.value
  console.log(configData)
  addLoading.value = true

  setConfiguration({
    cmd: "setting",
    settings: configData
  }).then((res) => {
    getPromptData()
    addModelDialogShow.value = false
  }).finally(() => {
    addLoading.value = false
  })
}

// 删除
const delModelLoading = ref(false)
const delModel = (modelName) => {
  ElMessageBox.confirm(
    '确定删除吗？',
    '警示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    console.log(configData.openai_config.chatgpt_models);
    delete configData.openai_config.chatgpt_models[modelName]
    delete curConfig.value.chatgpt_models[modelName]
    console.log(modelName);
    console.log(configData);
    console.log(curConfig.value);
    delModelLoading.value = true
    setConfiguration({
      cmd: "setting",
      settings: configData
    }).then((res) => {
      getPromptData()
    }).finally(() => {
      delModelLoading.value = false
    })
  })
}

// 修改
const curConfig = ref({}),
  executeDialogShow = ref(false),
  curTitle = ref('')
let curKey = ''
const setModel = (key, title) => {
  executeDialogShow.value = true
  curConfig.value = JSON.parse(JSON.stringify(modelData.value))
  curTitle.value = title
  curKey = key

  console.log(configData)
  console.log(configData.default_models)
  console.log(configData.default_models[key])

  // curConfig.value.chatgpt_models['gpt-3.5-turbo-0301'].default = false
  // curConfig.value.chatgpt_models['gpt-4-32k'].default = false
  // curConfig.value.chatgpt_models['gpt-4-0314'].default = false
  // curConfig.value.chatgpt_models['text-davinci-003'].default = false
  Object.keys(curConfig.value.chatgpt_models).forEach(key => {
    curConfig.value.chatgpt_models[key].default = false
  })

  curConfig.value.chatgpt_models[configData.default_models[key]].default = true

  console.log(curConfig.value)
}

const saveLoading = ref(false)
const save = () => {
  configData.openai_config = curConfig.value
  console.log(configData)
  console.log({
    cmd: "setting",
    settings: configData
  })
  saveLoading.value = true
  setConfiguration({
    cmd: "setting",
    settings: configData
  }).then((res) => {
    getPromptData()
    executeDialogShow.value = false
  }).finally(() => {
    saveLoading.value = false
  })
}

const handleCbChange = (v, key) => {
  console.log(v);
  console.log(key);
  Object.keys(curConfig.value.chatgpt_models).forEach(itemKey => {
    if (itemKey !== key) {
      curConfig.value.chatgpt_models[itemKey].default = false
    }
  })
  configData.default_models[curKey] = key
  console.log(configData);
}

onMounted(() => {
  getPromptData()
})
</script>

<style lang="less" scoped>
.manage-model {
  padding: 20px;

  .model-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .model-item {
    display: flex;
    align-items: center;
    width: 250px;
    height: 70px;
    background: #f6f6f6;
    border-radius: 6px;
    margin: 0 14px 14px 0;
    padding-left: 20px;
    cursor: pointer;

    .icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: #f5e2d0;
      border-radius: 50%;
      margin-right: 10px;

      .icon {
        color: #f19135;
      }
    }
    .title-wrap {
      .title {
        font-size: 15px;
        font-weight: 700;
        color: #1d1e22;
      }
      .sub-title {
        margin-top: 8px;
        font-size: 12px;
        text-align: left;
        color: #b8b6b6;
      }
    }
  }
}

.execute-box {
  position: relative;
  height: 700px;
  width: 700px;
  overflow: auto;

  .title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #1d1e22;
    padding: 10px 0 20px;
    border-bottom: 1px solid rgba(213,213,213,0.35);
    margin-bottom: 9px;
  }

  .btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #f19135;
    color: #fff;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 6px;
  }

  .config-form {
    .chatgpt-models {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      margin-bottom: 6px;
      .box-card {
        .card-header{
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: bold;

          .el-checkbox {
            height: auto;
          }
        }
      }
    }
  }

  .form-item {
    margin-top: 9px;
    .label {
      font-size: 16px;
      color: #1d1e22;
    }
    .input {
      width: 95%;
      height: 30px;
      border: 1px solid #d5d5d5;
      border-radius: 6px;
      margin-top: 6px;
      // padding: 1px 6px;
      outline: none;
    }
  }
}
  .add-model-btn {
    margin: 10px 20px 10px 20px;
    width: calc(100% - 40px);
  }
</style>