<template>
  <div class="contract-amendment" ref="contentRef" contenteditable="true">
    {{docResult}}
    <!-- 尊敬的矿产资源管理部门： 我（单位名称：测试）在此向贵部门申请矿业权，希望能够获得在指定区域内进行矿产资源勘查、开采的许可。以下是我们的申请内容： 一、申请人基本信息 1. 申请人名称：测试 2. 单位性质：企业 3. 注册地址：（请填写注册地址） 4. 法定代表人：（请填写法定代表人姓名） 5. 联系电话：（请填写联系电话） 6. 电子邮箱：（请填写电子邮箱） 二、申请矿区基本信息 1. 矿区名称：（请填写矿区名称） 2. 矿区位置：（请填写矿区所在地理位置） 3. 矿区面积：（请填写矿区面积） 4. 矿种：（请填写矿种） 5. 勘查阶段：（请填写勘查阶段） 6. 开采方式：（请填写开采方式） 三、申请理由及工作计划 1. 申请理由：（请详细阐述申请矿业权的原因，如矿产资源潜力、市场需求等） 2. 勘查工作计划：（请详细描述勘查工作的计划、步骤、预期目标等） 3. 开采工作计划：（请详细描述开采工作的计划、步骤、预期目标等） 4. 环保措施：（请详细描述在勘查、开采过程中采取的环保措施） 5. 安全生产措施：（请详细描述在 -->
    <button v-show="showAmendmentBtn" ref="amendmentBtnRef" class="amendment-btn" @click="handleAmendmentBtnClick">AI优化方案</button>

  </div>
    <Dialog v-model="isAmendmentDialogShow" @onClose="dialogClose" class="amendment-dialog">
      <div class="amendment-box">
        <div class="loading" v-if="optimizeLoading">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        <template v-else>
          <div class="top">
            <input class="input" type="text" placeholder="请选择一个优化方案" v-model="selectedOptimize">
            <div class="btn" @click="optimizeSubmit">提交</div>
          </div>

          <div class="scheme">
            <div class="scheme-wrap">
              <h1 class="title">优化方案：</h1>
              <ul class="scheme-list">
                <li :class="['scheme-item', {'active': selectedOptimizeIndex === index }]" v-for="(item, index) in optimizeList" :key="index" @click="handleSchemeClick(index)">
                  <span class="number">【{{index + 1}}】</span>
                  <span class="content">{{ item }}</span>
                </li>
              </ul>
            </div>

            <!-- <div class="scheme-wrap">
              <h1 class="title">优化方案：</h1>
              <ul class="scheme-list">
                <li class="scheme-item">
                  <span class="number">【1】</span>
                  fasdfsad啊撒旦范德萨分撒旦阿三撒
                </li>
                <li class="scheme-item">
                  <span class="number">【2】</span>
                  fasdfsad啊撒旦范德萨分撒旦阿三撒
                </li>
              </ul>
            </div> -->
          </div>
        </template>
      </div>
    </Dialog>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, nextTick, computed } from 'vue'

import { baseUrl } from '../../request/config'
import { gptQuery, gptOptimize } from '../../request/index'
import { generateId, getCurDate } from '../../utils/index'
import axios from 'axios'

import Dialog from '../../components/dialog.vue'

import { useRoute } from 'vue-router'
import { useStore } from "vuex"
const route = useRoute()
const store = useStore()
let token = computed(() => store.state.token)

const contentRef = ref(null);
const amendmentBtnRef = ref(null);
const showAmendmentBtn = ref(false);
const btnTop = ref(0);
const btnLeft = ref(0);
const selectedText = ref('')

const showAmendmentBtnHandler = () => {
  if (loading.value) return
  setTimeout(() => {
    const selection = window.getSelection();
    if (selection.toString().length > 0) {
      /* 一个 selection 包含多个 range，比如 firefox 中可以按住 ctrl 选中多段文字，其他浏览器只能选一段，这里选择第零个 range */
      const range = selection.getRangeAt(0);
      const { startContainer, endContainer, startOffset, endOffset } = range;
           //  起始节点        末尾节点  
      if (contentRef.value.contains(startContainer) && contentRef.value.contains(endContainer)) {
        const startRect = getBoundingRect(startContainer, startOffset);
        const endRect = getBoundingRect(endContainer, endOffset - 1);

        btnTop.value = Math.max(startRect.bottom, endRect.bottom);
        btnLeft.value = Math.min(startRect.left, endRect.left);

        showAmendmentBtn.value = true;

        selectedText.value = selection.toString();
      }
    } else {
      showAmendmentBtn.value = false;
    }
  }, 100)
};

const getBoundingRect = (container, offset) => {
  const range = document.createRange();
  range.setStart(container, offset);
  range.setEnd(container, offset);

  const rect = range.getBoundingClientRect();
  range.detach();

  return rect;
};

const getOptimize = () => {
  return gptOptimize({
    chatid: optimizeChatId,
    temperature: 0.5,
    cmd: "optimize_fragment",
    desire_word_num: 500,
    messages: [
      {
        role: "user",
        message_id: generateId(),
        timestamp: Date.now(),
        content: {
          text: "请帮优化一下这段文字",
          fragment: selectedText.value
        }
      }
    ]
  }, source.token)
}

const optimizeList = ref([])
// const optimizeList = ref(['方案1大法师发多少矮点发多少发多少矮\法师发多少矮点发多少发多少矮\nfdasg到点发多少发多少矮\nfdasg到点发多少发多少矮\nfdasg到噶是否打\n算撒旦啊撒点暗示', '方案2的发生各位噶多少\n功夫暗示暗示ADS爱上的说法阿斯达发多少暗示'])
const optimizeLoading = ref(false)
const CancelToken = axios.CancelToken;
let source = CancelToken.source();
const handleAmendmentBtnClick = async () => {
  window.getSelection().removeAllRanges();
  showAmendmentBtn.value = false;
  isAmendmentDialogShow.value = true
  selectedOptimizeIndex.value = null
  selectedOptimize.value = ''
  optimizeList.value = []
  optimizeLoading.value = true
  Promise.all([getOptimize(), getOptimize(), getOptimize()]).then(res => {
    console.log(res)
    res.forEach((item) => {
      optimizeList.value.push(item.data.result)
    })
    console.log('列表',optimizeList.value)
  }).finally(() => {
    optimizeLoading.value = false
  })
};
const dialogClose = () => {
  source.cancel('取消请求');
  source = CancelToken.source();
}
const optimizeSubmit = () => {
  console.log(docResult.value)
  console.log(selectedText.value)
  console.log(selectedOptimize.value)
  docResult.value = docResult.value.replace(selectedText.value, selectedOptimize.value)
  console.log(docResult.value.replace(selectedText.value, selectedOptimize.value))
  isAmendmentDialogShow.value = false
}

const selectedOptimizeIndex = ref(null)
const selectedOptimize = ref('')
const handleSchemeClick = (index) => {
  selectedOptimizeIndex.value = index
  selectedOptimize.value = optimizeList.value[index]
}

const scrollToBottom = () => {
  document.documentElement.scrollTop = document.documentElement.scrollHeight
}

const loading = ref(false),
  docResult = ref(''),
  fetchController = new AbortController();
const generateDoc = () => {
  const query = route.query
  const requirement = query.requirement
  const form = JSON.parse(query.form)

  loading.value = true
  gptQuery({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token.value
    },
    signal: fetchController.signal,
    body: JSON.stringify({
      chatid: chatId,
      cmd: "contract_draft",
      temperature: 0.5,
      desire_word_num: 500,
      messages: [{
        role: 'user',
        message_id: generateId(),
        timestamp: Date.now(),
        content: {
          text: requirement,
          meta_data: form
        }
      }]
    })
  }).then(async (response) => {
    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    let partialLine = '';
    async function read() {
      const { done, value } = await reader.read();
      if (done) {
        if (partialLine) {
          console.log(partialLine);
        }
        loading.value = false
        return;
      }
      const lines = decoder.decode(value, { stream: true }).split('\n');
      lines[0] = partialLine + lines[0];
      partialLine = lines.pop();

      for (const line of lines) {
        console.log("----------------------------")
        console.log(line);

        try {
          const valueObj = JSON.parse(line)
          const text = valueObj.message.content.text
          if (text !== '[start_info_of_msg]' && text !== '[end_info_of_msg]' && !text.includes('used_model')) {
            docResult.value += valueObj.message.content.text
          }
          scrollToBottom()
        } catch(e) {
          console.log('JSON 转换错误了，请检查返回数据格式：',e)
        }
      }

      return read();
    }

    await read();



    /* return new ReadableStream({
      start(controller) {
        return pump();
        function pump() {
          return reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              loading.value = false
              return;
            }
            loading.value = true
            const valueDecode = new TextDecoder().decode(value);
            try {
              const valueObj = JSON.parse(valueDecode)
              if (valueObj.message.content.text !== '[start_info_of_msg]' && valueObj.message.content.text !== '[end_info_of_msg]') {
                docResult.value += valueObj.message.content.text
              }
              scrollToBottom()
            } catch(e) {
              console.log(valueDecode)
              console.log('JSON 转换错误了，请检查返回数据格式：',e)
            }
            controller.enqueue(value);
            return pump();
          });
        }
      },
    }); */
  }).finally(() => {
    loading.value = false
  })
}

const isAmendmentDialogShow = ref(false)

let chatId = '',
  optimizeChatId = ''
onMounted(() => {
  document.addEventListener('mouseup', showAmendmentBtnHandler);
  chatId = generateId()
  optimizeChatId = generateId()
  generateDoc()
});

onBeforeUnmount(() => {
  document.removeEventListener('mouseup', showAmendmentBtnHandler);
  fetchController.abort();
});

watch(
  () => selectedText.value,
  () => {
    if (showAmendmentBtn.value) {
      amendmentBtnRef.value.style.top = `${btnTop.value + 10}px`;
      amendmentBtnRef.value.style.left = `${btnLeft.value}px`;
    }
  }
);
watch(
  () => token.value,
  (newToken, oldToken) => {
    generateDoc()
  }
);
</script>

<style lang="less" scoped>
.contract-amendment {
  padding: 10px 20%;
  line-height: 30px;
  outline: none;
  white-space: pre-wrap;
}
.amendment-btn {
  position: fixed;
  background: #f19135;
  border-radius: 4px;
  border: 0;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
}

.amendment-box {
  position: relative;
  width: 50vw;
  height: 70vh;

  .top {
    display: flex;
    height: 48px;
    align-items: center;
    box-shadow: 0px 6px 27px 0px rgba(0,0,0,0.08);
    border-radius: 12px 12px 0 0;

    .input {
      width: 86%;
      border: 0;
      padding: 0 2%;
      outline: none;
    }

    .btn {
      width: 88px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #f19135;
      color: #fff;
      border-radius: 6px;
      margin-right: 2%;
      cursor: pointer;
    }
  }
  .scheme {
    height: calc(100% - 48px);
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .scheme-wrap {
    color: #1d1e22;
    .title {
      margin: 2% 0 0 2%;
      font-size: 20px;
    }
    .scheme-list {
      .scheme-item {
        display: flex;
        position: relative;
        padding: 2%;
        font-size: 16px;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          left: 2%;
          bottom: 0;
          width: 96%;
          height: 1px;
          border-bottom: 2px dotted #d6d6d6;
        }

        &.active {
          background: rgba(241,145,53,0.10);
        }

        .number {
          display: inline-block;
          flex: 0 0 6%;
          line-height: 26px;
          font-size: 14px;
        }
        .content {
          flex: 1;
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 78px;
    transform: translate3d(-39px, -50%, 0);
  }
}

// loading
.lds-ellipsis {
  display: inline-block;
  position: relative;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #9b9b9b;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media screen
  and (max-device-width: 1024px) {
  .amendment-box {
    width: 60vw;

    .scheme-wrap {
      .title {
        margin: 3% 0 0 3%;
        font-size: 18px;
      }
    }
  }
}
</style>

<style>
.amendment-dialog .dialog-content {
  padding: 0 !important;
}
</style>