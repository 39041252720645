import { createRouter, createWebHistory, createWebHashHistory, onBeforeRouteUpdate  } from 'vue-router'
import store from '../store'
// import router from '../router'

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import("../views/chat/index.vue"),
  },
  {
    path: "/drafting",
    name: "Drafting",
    component: () => import("../views/contract-drafting/index.vue"),
  },
  {
    path: "/amendment",
    name: "Amendment",
    component: () => import("../views/contract-amendment/index.vue"),
  },
  {
    path: "/manage",
    name: "Manage",
    component: () => import("../views/manage/index.vue"),
    children: [
      {
        path: "document",
        name: "Document",
        component: () => import("../views/manage-doc/index.vue"),
      },
      {
        path: "prompt",
        name: "Prompt",
        component: () => import("../views/manage-prompt/index.vue"),
      },
      {
        path: "model",
        name: "Model",
        component: () => import("../views/manage-model/index.vue"),
      },
      {
        path: "knowledge-base",
        name: "KnowledgeBase",
        component: () => import("../views/manage-knowledge-base/index.vue"),
      },
    ],
  },
  {
    path: "/manage/login",
    component: () => import("../views/manage-login/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const navigationType = performance.navigation.type;
  if (navigationType === performance.navigation.TYPE_RELOAD) {
    console.log('刷新');
    next()
  } else {
    const toPath = to.path;
    const fromPath = from.path;
    
    console.log('fromPath',fromPath);
    console.log('toPath',toPath);
  
    if (toPath === '/manage/login') {
      next()
    }
  
    // 从前台页面跳到后台页面时退出登录并跳到后台登录页
    if (toPath.includes('/manage') && !fromPath.includes('/manage')) {
      console.log('去登录');
      store.commit('clearToken');
      next('/manage/login');
    } else {
      next();
    }
  }
});

export default router
