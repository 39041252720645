<template>
  <div class="manage-login">
    <div class="content">
      <div class="ai-bg-wrap">
        <!-- <img class="ai-bg" src="../../assets/bg.png" alt="ai-bg"> -->
      </div>
      <div class="login">
        <h1 class="title">AI法律助手后台管理系统</h1>
        <div class="input-wrap">
          <div class="label">账号</div>
          <input type="text" placeholder="请输入您的账号" v-model="account">
        </div>
        <div class="input-wrap">
          <div class="label">密码</div>
          <input type="password" placeholder="密码" v-model="password">
        </div>
        <div class="login-btn" @click="loginSubmit">登录</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useStore } from "vuex"
import { useRouter, useRoute  } from 'vue-router'
import { createToaster } from "@meforma/vue-toaster";

import { login } from '../../request/index'

const toaster = createToaster({
  position: "top"
});
const store = useStore()
const router = useRouter()
const route = useRoute()

const account = ref('')
const password = ref('')
const loginSubmit = () => {
  login({
    username: account.value,
    password: password.value
  }).then(({ data }) => {
    toaster.success('登录成功')
    const token = data.access_token
    localStorage.token = token
    store.commit('setToken', token)
    store.commit('setIsLoginBoxShow', false)
    router.replace('/manage/document')
  }).catch(err => {
    console.log(err)
    toaster.error('登录失败，请检查账号密码！')
  })
}
</script>

<style lang="less" scoped>
.manage-login {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  height: 100vh;
  width: 100%;
  background: url('../../assets/login_bg1.png');
  background-size: 100% 100%;

  .content {
    display: flex;
    width: 1100px;
    height: 590px;
    // width: 70vw;
    // height: 60vh;
    // max-width: 1536px;
    // max-height: 656px;
    background: #fff;
    border-radius: 12px;

    .ai-bg-wrap {
      flex: 1.6;
      background: url('../../assets/login_bg2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 10px 0 0 10px;
      /* .ai-bg {
        width: 100%;
        height: 100%;
      } */
    }

    .login {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 24px;
        font-weight: 700;
        color: #232323;
        margin: 80px 0 96px 0;
      }
      .input-wrap {
        width: 70%;
        margin-bottom: 30px;
        .label {
          color: #232323;
        }
        input {
          width: 100%;
          height: 44px;
          border: 1px solid #d5d5d5;
          border-radius: 6px;
          outline: none;
          margin-top: 10px;
          padding: 0 10px;
          box-sizing: border-box;
        }
      }
    }
    .login-btn {
      margin-top: 46px;
      width: 70%;
      height: 44px;
      line-height: 44px;
      background: #f19135;
      border-radius: 5px;
      text-align: center;
      color: #fff;
    }
  }
}
</style>