<template>
  <div class="contract-drafting">
    <div class="header">
      <select name="contractTypes" id="contractTypeSelect" v-model="selectedOption">
        <option v-for="option in options" :key="option.id" :value="option">{{ option.label }}</option>
      </select>

      <div class="generate-btn" @click="handleSubmitClick">提交</div>
    </div>

    <div class="form-wrap">
      <div class="form-block" v-for="(item, index) in selectedOption.inputs" :key="index">
        <h1 class="title">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="item.label.includes('基本信息') ? '#icon-jibenxinxi' : '#icon-qitaxinxi'"></use>
          </svg>
          {{item.label}}
        </h1>
        <div class="form-list">
          <div class="form-item" v-for="input in item.value" :key="input.id">
            <div class="label">{{input.placeholder}}：</div>
            <input class="value" type="text" placeholder="请输入" v-model="formData[input.id]">
          </div>
        </div>
      </div>
    </div>

    <Dialog v-model="isRequirementDialogShow">
      <div class="requirement-box">
        <div class="title">输入要求</div>

        <div class="input-wrap">
          要求：
          <input class="input" type="text" placeholder="请输入您的要求" v-model="requirement">
        </div>

        <div class="generate-btn" @click="generate">生成</div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { optionsData } from './options'

import Dialog from '../../components/dialog.vue'

import { useRouter } from 'vue-router'
const router = useRouter()

const selectedOption = ref(null)
const options = reactive(optionsData)
selectedOption.value = options[0]

const formData = reactive({})
const isRequirementDialogShow = ref(false)
const requirement = ref('')
const handleSubmitClick = () => {
  console.log(formData);
  isRequirementDialogShow.value = true
  requirement.value = `请帮我写一份${selectedOption.value.label}`
}

const generate = () => {
  router.push({
    path: '/amendment',
    query: {
      requirement: requirement.value,
      form: JSON.stringify(formData)
    }
  })
}
</script>

<style lang="less" scoped>
.contract-drafting {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: #f19135;
    box-shadow: 0px 2px 24px 0px rgba(175,121,69,0.22);
    padding: 0 20%;

    #contractTypeSelect {
      height: 40px;
      flex: 0 0 70%;
      border: 0;
      border-radius: 6px;
      padding: 0 10px;
      outline: none;
    }
  }
  .generate-btn {
    height: 40px;
    line-height: 40px;
    padding: 0 4%;
    background: #ffffff;
    color: #f19135;
    border-radius: 6px;
    cursor: pointer;
  }

  .form-wrap {
    padding: 0 20% 20px;
  }

  .form-block {
    margin-top: 4%;
    .title {
      font-size: 18px;
      color: #F19135;
      margin-bottom: 2%;
    }

    .form-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 4%;
      grid-auto-rows: minmax(0, 1fr);

      .form-item  {
        margin-bottom: 4%;

        .label {
          margin-bottom: 2%;
        }
        .value {
          width: 100%;
          height: 30px;
          border: 1px solid #d5d5d5;
          border-radius: 4px;
          text-indent: 4px;
        }
      }
    }
  }

  .requirement-box {
    width: 300px;
    height: 250px;
    padding: 0 20px;
    box-sizing: border-box;

    .title {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      color: #1d1e22;
      padding: 10px 0 20px;
      border-bottom: 1px solid rgba(213,213,213,0.35);
    }
    .input-wrap {
      margin-top: 36px;
      font-size: 14px;
    }
    input {
      width: 100%;
      height: 36px;
      outline: none;
      border-radius: 6px;
      padding: 0 6px;
      box-sizing: border-box;
      border: 1px solid #d5d5d5;
      border-radius: 6px;
      margin-top: 8px;
    }

    .generate-btn {
      width: 100%;
      text-align: center;
      background: #f19135;
      color: #fff;
      box-sizing: border-box;
      margin-top: 36px;
    }
  }
}
@media screen
  and (max-device-width: 1024px) {
  .contract-drafting {
    .header {
      padding: 0 20px;
    }
    .form-wrap {
      padding: 0 20px 20px;
    }
  }
}
@media screen
  and (max-device-width: 580px) {
  .contract-drafting {
    .form-block {
      .form-list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 6%;
      }
    }
  }
}
</style>