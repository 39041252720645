<template>
  <header :class="['header', { 'chat-header': router.currentRoute.value.path === '/chat' }]" v-if="shouldShowHeader">
    <div :class="['content-wrap']"><!-- , { 'move-right': router.currentRoute.value.path === '/chat' } -->
      <div class="content">
        <div class="menu-btn" v-show="isMenuShow" @click="handleMenuClick"></div>
        <div class="logo-wrap" @click="jumpToHome">
          <img class="logo" v-show="!isMenuShow" src="../assets/logo_big.png" alt="logo">
        </div>
        <div class="user-wrap">
          <div class="website-name" @click="jumpToYuRen">雨仁官网</div>
          <div class="user">
            <template v-if="!logined">
              <span class="login" @click="handleLoginClick">登录</span>
              <span class="sign-up">注册</span>
            </template>
            <img v-else class="avatar" src="../assets/user_avatar.png" alt="avatar">
          </div>
        </div>
      </div>
    </div>

    <Dialog v-model="isLoginBoxShow" @onClose="onDialogClose">
      <div class="login-box">
        <h1 class="title">登录</h1>
        <div>
          <div class="input-box">
            <span class="label">账号：</span>
            <input v-model.trim="account" class="value" type="text" placeholder="请输入">
          </div>
          <div class="input-box">
            <span class="label">密码：</span>
            <input v-model="password" class="value" type="password" placeholder="请输入">
          </div>
        </div>
        <div class="login-btn" @click="loginSubmit">登录</div>
      </div>
    </Dialog>
  </header>
</template>

<script setup>
import Dialog from '../components/dialog.vue'

import { ref, watch, computed } from 'vue'
import { useStore } from "vuex"
import { useRouter, useRoute  } from 'vue-router'
import { createToaster } from "@meforma/vue-toaster";

import { login } from '../request/index'

const toaster = createToaster({
  position: "top"
});
const store = useStore()
const router = useRouter()
const route = useRoute()
const shouldShowHeader = ref(false)

const isMenuShow = ref(false)
// 设置左上角菜单按钮是否显示 和 侧边栏是否显示
const setAside = () => {
  if (router.currentRoute.value.path === '/chat') {
    if (window.innerWidth > 1024) {
      store.commit('setIsAsideShow', true)
      isMenuShow.value = false
    } else {
      isMenuShow.value = true
      store.commit('setIsAsideShow', false)
    }
  } else {
    isMenuShow.value = false
  }
}
setAside()
// 路由跳转 和 resize 时动态设置侧边栏
watch(router.currentRoute,() => {
 setAside()
 shouldShowHeader.value = !route.path.includes('manage');
})
window.addEventListener('resize', () => {
  setAside()
})

// 切换侧边栏显示
const handleMenuClick = () => {
  store.commit('setIsAsideShow', !store.state.isAsideShow)
}

const jumpToYuRen = () => {
  window.open('https://www.yurenlawyer.com/', '_blank')
}
const jumpToHome = () => {
  router.push('/')
}

let isLoginBoxShow = computed(() => store.state.isLoginBoxShow)
let logined = computed(() => store.state.token)
const account = ref('')
const password = ref('')
const handleLoginClick = () => {
  store.commit('setIsLoginBoxShow', true)
}
const onDialogClose = () => {
  store.commit('setIsLoginBoxShow', false)
}
const loginSubmit = () => {
  login({
    username: account.value,
    password: password.value
  }).then(({ data }) => {
    toaster.success('登录成功')
    const token = data.access_token
    localStorage.token = token
    store.commit('setToken', token)
    store.commit('setIsLoginBoxShow', false)
  }).catch(err => {
    console.log(err)
    toaster.error('登录失败，请检查账号密码！')
  })
}
</script>

<style lang="less" scoped>
.header {
  position: sticky;
  top: 0px;
  z-index: 2;
  margin: 0px;
  // background: rgba(0,0,0,0.79);
  background: rgb(11, 34, 68);

  &.chat-header {
    background: #202124;

    .content-wrap {
      padding-left: 344px;
    }
  }
  // width: 100%;
  // height: 93px;
  // background: #383C47;
  // box-sizing: border-box;

  .content-wrap {
    position: static;
    display: flex;
    height: 93px;
    padding: 0 40px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
    background-size: initial;
    // background-color: rgba(0,0,0,0.79);
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-attachment: initial;
    background-image: none;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // height: 100%;
    // padding: 0 20%;
    // box-sizing: border-box;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1120px;
      width: 100%;
      margin: auto;
      background-color: transparent;
    }
    &.move-right {
      padding-left: 344px;
    }

    .menu-btn {
      // display: none;
      width: 25px;
      height: 3px;
      background: #fff;
      position: relative;
    }
    .menu-btn::before{
      position: absolute;
      top: -8px;
      content: '';
      width: 25px;
      height: 3px;
      background: #fff;
    }
    .menu-btn::after{
      position: absolute;
      bottom: -8px;
      content: '';
      width: 25px;
      height: 3px;
      background: #fff;
    }

    .logo-wrap {
      // width: 120px;
      // height: 28px;
      cursor: pointer;
      .logo {
        // width: 98%;
        // height: 98%;
        // display: block;
        max-width: 100%;
        object-fit: contain;
        width: 200px;
        // height: 53px;
      };
    }
    .user-wrap {
      display: flex;
      align-items: center;
      color: #FFFFFF;

      .website-name {
        font-size: 20px;
        margin-right: 46px;
        cursor: pointer;
      }
      .user {
        display: flex;
        align-items: center;

        .login {
          margin-right: 46px;
          cursor: pointer;
        }
        .sign-up {
          margin-right: 46px;
          cursor: pointer;
        }

        .avatar {
          width: 46px;
          height: 46px;
          border-radius: 50%;
        }
      }
    }
  }
}

.login-box {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  width: 360px;
  height: 290px;

  .title {
    font-size: 20px;
    text-align: center;
    margin: 12px 0 11%;
  }
  .input-box {
    display: flex;
    align-items: center;
    height: 38px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    margin: 0 10% 22px;
    padding: 2px 0 2px 10px;

    .label {
      display: inline-block;
      vertical-align: top;
      // margin-right: 10px;
      font-weight: bold;
      font-size: 16px;
      width: 24%;
    }
    .value {
      display: inline-block;
      vertical-align: top;
      border: 0;
      height: 90%;
      outline: none;
      font-size: 16px;
      width: 80%;
    }
  }

  .login-btn {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #f19135;
    border-radius: 5px;
    margin: 12% 10% 0;
    color: #fff;
  }
}

@media screen
  and (max-device-width: 1024px) {
    .header {
      padding-left: 0;
      &.chat-header {
        .content-wrap {
          padding-left: 0;
        }
      }
      .content-wrap {
        height: 80px;
        padding: 0;
        .content {
          margin-left: 0;
          padding: 0 20px;

          .user-wrap {
            .website-name {
              font-size: 18px;
              margin-right: 20px;
            }
            .user {
              .login {
                margin-right: 20px;
              }
              .sign-up {
                margin-right: 0;
              }
              .avatar {
                width: 36px;
                height: 36px;
                border-radius: 50%;
              }
            }
          }

          .logo-wrap {
            .logo {
              height: 30px;
              width: auto;
            }
          }
        }
      }
    }

    .login-box {
      width: 300px;
      height: 280px;
    }
}
</style>