<template>
  <div class="manage-kb">
    <div class="top">
      <el-button type="danger" @click="delKbHandler" :disabled="!selectedKb.length" :loading="delLoading">删除</el-button>
    </div>
    
    <el-table
      v-loading="getKbDataLoading"
      :data="kbData.data"
      style="width: 100%; margin-top: 10px;"
      @selection-change="handleSelectionChange"
      height="76vh"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        prop="filename"
        label="知识库文件路径">
      </el-table-column>
    </el-table>

    <el-pagination
      background
      style="float:right; margin-top: 20px;"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 50, 100, 200]"
      :total="kbData.total"
      v-model:current-page="page"
      :page-size="size"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import { getKb, delKb, loadKb } from '../../request/index'

import { ElMessageBox } from 'element-plus'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({
  position: "top"
});

const  kbData = ref([]),
  getKbDataLoading = ref(false),
  page = ref(1),
  size = ref(10),
  status = ref(null)
  
const getKbData = () => {
  getKbDataLoading.value = true
  getKb({
    page: page.value, // 后端 page 从 0 开始
    page_size: size.value
  }).then(res => {
    kbData.value = res.data
  }).finally(() => {
    getKbDataLoading.value = false
  })
}
const handleSizeChange = (v) => {
  page.value = 1
  page_size.value = v
  getKbData()
}
const handleCurrentChange = (v) => {
  page.value = v
  getKbData()
}
const handleStatusChange = (v) => {
  page.value = 1
  if (v === '') {
    status.value = null
  }
  getKbData()
}

const selectedKb = ref([])
const handleSelectionChange = (v) => {
  selectedKb.value = []
  for (let i = 0; i < v.length; i++) {
    selectedKb.value.push(v[i].filename)
  }
  console.log(selectedKb.value)
}

const loadKbLoading = ref(false)
const delLoading = ref(false)
const delKbHandler = () => {
  ElMessageBox.confirm(
    '确定删除吗？',
    '警示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    delLoading.value = true
    delKb(selectedKb.value).then((res) => {
      console.log(res.data)
      const resData = res.data
      if (resData.code === 0){
        toaster.success('删除成功')
      } else{
        toaster.error(resData.msg)
      }
      getKbData()
    }).finally(() => {
      delLoading.value = false
    })
  })
}


onMounted(() => {
  getKbData()
})
</script>

<style scoped lang="less">
.manage-kb {
  padding: 20px;
  .top {
     float: right;
     margin-bottom: 10px;
  }
}
</style>